import React from "react";

function App() {
  return (
    <div className="App">
      <header className="header">
        <h1>IDA Radio Helsinki</h1>
      </header>

      <div className="lead">
        <p>
          IDA is an independent cultural platform that started in Tallinn, May
          2018 & is opening a station in Helsinki March 2020. IDA operates as an
          online-radio & event promoter.
        </p>
      </div>

      <div className="row">
        <div className="column">
          <h3>IDA Radio</h3>
          <p>
            The radio program offers a wide range from classical, ambient, jazz
            & folk to various forms of modern contemporary dance music &
            educative talk shows from local art institutions, progressive
            activists, writers etc. Around 200 hosts have aired 3000 shows from
            it’s small studio in Tallinn. More than 150 deejay's & radio hosts
            will be making the upcoming IDA Helsinki’s program.
          </p>
        </div>

        <div className="column">
          <h3>Mission</h3>
          <p>
            IDA’s mission is to offer a platform for local talent to showcase
            their creation and creativity. IDA aims to enrich local mediasphere
            and create an uncensored space for expression of thought. Studio
            works as a physical space to connect like-minded people to start new
            dialogues and collaborations. IDA contributes to cultural export by
            creating an online archive of shows, inviting international guests
            and organising different collaboration projects.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h3>IDA Events</h3>
          <p>
            Events vary from club nights to concerts with occasional special
            formats like residencies, festival co-productions, book clubs etc.
          </p>
          <p>
            IDA is being run by a group of volunteers and aims to enrich &
            challenge the local scenes of media & culture.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h3>IDA Helsinki</h3>
          <p>
            Helsinki has been lacking an output for the marginal music scene,
            that could help the scene grow and get stronger. IDA steps in to
            change that. Helsinki’s music scene is blooming, and we want to show
            that to the world.
          </p>
          <p>
            Support your local,
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
              className="paypal-form"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="LAD7CCF956S6G"
              />
              <button
                className="paypal-link"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
                button-text=""
              >
                donate here
              </button>
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </p>
        </div>
      </div>

      <a
        className="ida-button button"
        href="https://idaidaida.net"
        target="_blank"
      >
        idaidaida.net
      </a>
    </div>
  );
}

export default App;
